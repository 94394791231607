import e from './enums';

export default {
  navServices: { ka: 'სერვისები' },
  navAboutUs: { ka: 'ჩვენს შესახებ' },
  navForClients: { ka: 'მომხმარებლებისთვის' },
  navForPartners: { ka: 'პარტნიორებისთვის' },
  navContact: { ka: 'კონტაქტი' },
  navLogin: { ka: 'შესვლა' },
  navProfile: { ka: 'პროფილი' },

  mainPageSliderButton: { ka: 'დახმარების გამოძახება' },
  mainPageSliderTitle: { ka: 'პირველად საქართველოში' },
  mainPageSliderText: { ka: 'სასწრაფო დახმარება შენი ავტომობილისთვის.' },

  mainPageSecondSectionButton: { ka: 'გადმოწერე აპი' },
  mainPageSecondSectionTitle: { ka: 'პირველად საქართველოში' },
  mainPageSecondSectionText: { ka: ['გადმოწერე აპი', 'აირჩიე სერვისი', 'გამოიძახე მარტივად და სწრაფად'] },
  mainPageSecondSectionText2: { ka: '' },

  mainPageThirdSectionCards: [{
    title: { ka: 'გამოიძახე მარტივად' },
    text: { ka: '' },
    url: 'img/demos/auto-services/icons/checklist.svg',
  }, {
    title: { ka: 'დაზოგე დრო' },
    text: { ka: '' },
    url: 'img/demos/auto-services/icons/save-time.png',
  }, {
    title: { ka: 'მიიღე ფასდაკლებები' },
    text: { ka: '' },
    url: 'img/demos/auto-services/icons/discount.png',
  }],

  mainPageServicesSectionTitle: { ka: 'ჩვენი სერვისები' },
  mainPageServicesSectionSubTitle: { ka: 'ჩვენი სერვისები' },

  mainPageServicesSectionCards: [{
    title: { ka: 'ევაკუატორის მომსახურება' },
    url: 'img/sos/tow.webp',
    urlTo: '/tow',
  }, {
    title: { ka: 'საბურავის შეცვლა' },
    url: 'img/sos/tyre.jpg',
    urlTo: '/tyre',
  }, {
    title: { ka: 'აკუმულატორის დატენვა' },
    url: 'img/sos/battery.jpeg',
    urlTo: '/battery',
  }, {
    title: { ka: 'საწვავის მიწოდება' },
    url: 'img/sos/fuel.jpg',
    urlTo: '/fuel',
  }, {
    title: { ka: 'მძღოლის მომსახურება' },
    url: 'img/sos/driver.jpg',
    urlTo: '/sober',
  }],

  mainPageFAQTitle: { ka: 'ხშირად დასმული კითხვები' },
  mainPageFAQSubTitle: { ka: 'ხშირად დასმული კითხვები' },

  mainPageFAQ: [{
    title: { ka: 'რა არის საგზაო დახმარების აპლიკაცია SOS AUTO?' },
    text: { ka: 'SOS AUTO-ს აპლიკაცია არის მობილური აპლიკაცია, რომელიც უზრუნველყოფს სასწრაფო საგზაო დახმარებას თქვენი ავტომობილისთვის.' },
  }, {
    title: { ka: 'რა მომსახურებებით შეგიძლიათ ისარგებლოთ SOS AUTO-ს აპლიკაციით?' },
    text: {
      ka: `SOS AUTO-ს აპლიკაციით თქვენ შეგიძლია ისარგებლოთ შემდეგი სერვისებით:
      <ul>
      <li>ევაკუატორის მომსახურება</li>
      <li>საბურავის შეცვლა</li>
      <li>აკუმულატორის დატენვა</li>
      <li>საწვავის მიწოდება</li>
      <li>მძღოლის მომსახურება</li>
      </ul>`,
    },
  }, {
    title: { ka: 'როგორ მუშაობს საგზაო დახმარების აპლიკაცია SOS AUTO ?' },
    text: { ka: 'SOS AUTO-ს აპი მუშაობს მარტივად, მომხმარებელი დახმარებას ითხოვს აპლიკაციის საშუალებით, აპლიკაცია იყენებს GPS-ს მომხმარებლის ზუსტი მდებარეობის დასადგენად, შემდეგ, სწრაფი დახმარების გასაწევად, სერვისის პროვაიდერებთან აგზავნის მომხმარებლის ზუსტ ლოკაციას.' },
  }, {
    title: { ka: 'რა ღირს SOS AUTO-ს აპლიკაციიდან მომსახურების გამოძახება?' },
    text: { ka: 'SOS AUTO-ს აპლიკაციაში სერვისების ღირებულება განსხვავებულია. მომსახურების არჩევის შემდეგ აპლიკაცია თავად დაგითვლით ფასს ზედმეტი ფასნამატის გარეშე.' },
  }, {
    title: { ka: 'სად მუშაობს SOS AUTO-ს აპლიკაცია?' },
    text: { ka: 'SOS AUTO-ს აპლიკაცია მოგემსახურებათ საქართველოს ტერიტორიაზე, დღე-ღამის ნებისმიერ დროს - 24/7-ზე.' },
  }, {
    title: { ka: 'როგორ დაგიკავშირდეთ?' },
    text: { ka: 'შეგიძლიათ დაგვიკავშირდეთ ან მოგვწეროთ. <br/>ტელ: +995 577 00 00 68 <br/>info@sosoauto.ge' },
  }],

  problemTypes: {
    [e.problemType.TOW_JUMBO]: { ka: 'ევაკუატორი (3ტონა+)' },
    [e.problemType.TOW_SPIDER]: { ka: 'ევაკუატორი (ობობა)' },
    [e.problemType.TOW]: { ka: 'ევაკუატორი' },
    [e.problemType.BATTERY]: { ka: 'აკუმულატორი' },
    [e.problemType.TYRE]: { ka: 'საბურავი' },
    [e.problemType.LOCKED_DOOR]: { ka: 'ჩაკეტილი კარი' },
    [e.problemType.FUEL]: { ka: 'საწვავი' },
    [e.problemType.SOBER]: { ka: 'მძღოლი' },
  },
  subProblems: {
    sub_battery: { ka: 'აკუმულატორი' },
    sub_tyre: { ka: 'საბურავი' },
    sub_locked_door: { ka: 'ჩაკეტილი კარი' },
    sub_fuel: { ka: 'საწვავი' },
    sub_sober: { ka: 'მძღოლი' },
  },
  carType: {
    [e.carType.TOW_JUMBO]: { ka: 'ევაკუატორი (3ტონა+)' },
    [e.carType.TOW_SPIDER]: { ka: 'ევაკუატორი (ობობა)' },
    [e.carType.TOW]: { ka: 'ევაკუატორი' },
    [e.carType.SEDAN]: { ka: 'მსუბუქი ავტომობილი' },
  },

  profilePageHeaders: {
    profile: { ka: 'პროფილი' },
    services: { ka: 'სერვისები' },
    servicesMap: { ka: 'სერვისების რუკა' },
    orders: { ka: 'შეკვეთები' },
    paymentMethods: { ka: 'გადახდის მეთოდები' },
    verificationRequests: { ka: 'ვერიფიკაციის მოთხოვნები' },
    verifiedServices: { ka: 'ვერიფიცირებული სერვისები' },
    serviceReview: { ka: 'სერვისის ვერიფიკაცია' },
    userList: { ka: 'მომხმარებლების სია' },
    logout: { ka: 'გამოსვლა' },
  },
};
