/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

function SiteFooter() {
  return (
    <footer id="footer" className="border-0 mt-0">
      <hr className="bg-light opacity-2 my-0" />
      <div className="container pb-5">
        <div className="row text-center text-md-start py-4 my-5">
          <div className="col-md-6 col-lg-3 align-self-center text-center text-md-start text-lg-center mb-5 mb-lg-0">
            <a href="/">
              <img
                alt="Porto"
                        // width={123}
                height={65}
                data-sticky-width={82}
                data-sticky-height={40}
                data-sticky-top={84}
                src="/img/sos/LOGO1.svg"
              />
            </a>
          </div>
          <div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">ჩვენს შესახებ</h5>
            <ul className="list list-unstyled">
              <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5 mb-1">ტელეფონი</span>
                <ul className="list list-unstyled font-weight-light text-3-5 mb-0">
                  <li className="text-color-light line-height-3 mb-0">
                    <a href="tel:+995577000068" className="text-decoration-none text-color-light text-color-hover-default">+995 577 000 068</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="social-icons social-icons-medium">
              <li className="social-icons-instagram">
                <a href="http://www.instagram.com/" className="no-footer-css" target="_blank" title="Instagram" rel="noreferrer"><i className="fab fa-instagram" /></a>
              </li>
              <li className="social-icons-twitter mx-2">
                <a href="http://www.twitter.com/" className="no-footer-css" target="_blank" title="Twitter" rel="noreferrer"><i className="fab fa-twitter" /></a>
              </li>
              <li className="social-icons-facebook">
                <a href="http://www.facebook.com/" className="no-footer-css" target="_blank" title="Facebook" rel="noreferrer"><i className="fab fa-facebook-f" /></a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">სერვისები</h5>
            <ul className="list list-unstyled mb-0">
              <li className="mb-0">ევაკუატორის მომსახურება</li>
              <li className="mb-0">საბურავის შეცვლა</li>
              <li className="mb-0">აკუმულატორის დატენვა</li>
              <li className="mb-0">საწვავის მიწოდება</li>
              <li className="mb-0">მძღოლის მომსახურება</li>
            </ul>
          </div>
        </div>
        <div className="container py-2">
          <div className="row">
            <div className="col">
              <p className="text-center text-3 mb-0">Sos Auto © 2023. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
