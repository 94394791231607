/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import apiCall from '../../api';
import { useUser } from '../../lib/atoms';

function OrderList() {
  const { user, userType } = useUser();
  const bottomEl = useRef(null);

  const [users, setUsers] = useState([]);
  const [startAfter, setStartAfter] = useState(undefined);
  const [loadMoreText, setLoadMoreText] = useState('Load more');

  const [userToDelete, setUserToDelete] = useState(null);
  const [userToDeleteInput, setUserToDeleteInput] = useState('');

  const loadText = 'Load more';
  const loadingText = 'Loading...';
  const loadMore = () => {
    if (loadMoreText === loadText && startAfter) {
      setLoadMoreText(loadingText);
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
      apiCall('/get-all-users', {
        idToken: user.accessToken,
        startAfter,
      }).then((response) => {
        const u = response.users;
        if (u) {
          setUsers([...users, ...u]);
          if (u.length > 0) {
            setStartAfter(u[u.length - 1].id);
            setLoadMoreText(loadText);
          } else {
            setLoadMoreText('No more');
          }
          setTimeout(() => bottomEl?.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
        }
      });
    }
  };

  const getAllUsers = async () => {
    const response = await apiCall('/get-all-users', { idToken: user.accessToken });
    const u = response.users;
    if (u.length > 0) {
      setUsers(u.filter((usr) => usr.userType !== 'super'));
      setStartAfter(u[u.length - 1].id);
    }
  };

  useEffect(() => {
    if (user?.uid && userType) {
      // TODO filters
      getAllUsers();
    }
  }, [user?.uid, userType]);

  return (
    <div>
      <div className="container pt-3 pb-2">
        <div className="row pt-2">
          <div className="col-lg-12">
            <table
              className="table table-striped  text-light"
              style={{
                // display: 'block',
                // overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <thead>
                <tr>
                  {['email', 'firstname', 'lastname', 'iban', 'nid', 'phone', 'userType', ''].map((k) => (
                    <th style={{ color: 'white' }}>
                      {k}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {users.map((u) => (
                  <tr>
                    {['email', 'firstname', 'lastname', 'iban', 'nid', 'phone', 'userType'].map((k) => (
                      <td style={{ color: 'white' }}>
                        {u[k]}
                      </td>
                    ))}
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          setUserToDelete(u);
                          setUserToDeleteInput('');
                        }}
                      >
                        წაშლა
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {startAfter && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <a href="#" ref={bottomEl} className="btn btn-rounded btn-primary btn-modern" onClick={loadMore}>
                {loadMoreText}
              </a>
            )}
          </div>
        </div>
      </div>

      <Modal className="text-left" show={userToDelete != null} onHide={() => setUserToDelete(null)} centered>
        <Modal.Header className="text-light" closeButton>
          <Modal.Title>მომხმარებლის წაშლა</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!userToDelete?.loading && (
          <>
            <table className="text-light text-left">
              <tbody style={{ width: '100%' }}>
                {['email', 'firstname', 'lastname', 'iban', 'nid', 'phone', 'userType'].map((k) => (
                  <tr>
                    <td>
                      <h5>
                        {k}
                        :
                      </h5>
                    </td>
                    <td style={{ width: '15px' }} />
                    <td>
                      <h5>{userToDelete?.[k]}</h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />

            <h4>დაწმუნებული ხართ?</h4>
            <h5>თუ დაწმუნებული ხართ აკრიფე DELETE და დააჭირე წაშლას.</h5>
            <input
              type="text"
              value={userToDeleteInput}
              onChange={(event) => setUserToDeleteInput(event.target.value)}
              className="form-control form-control-lg text-4 text-light "
              style={{ backgroundColor: '#4f555a' }}
            />
          </>
          )}

          {userToDelete?.loading && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
          )}
        </Modal.Body>
        {!userToDelete?.loading && (
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => setUserToDelete(null)}
          >
            გაუქმება
          </Button>
          <Button
            variant="success"
            onClick={() => {
              if (userToDeleteInput.toLocaleLowerCase() === 'delete') {
                setUserToDelete({ loading: true });

                apiCall('/delete-user', {
                  idToken: user.accessToken,
                  deleteUid: userToDelete.id,
                }).then(() => {
                  getAllUsers().then(() => {
                    setUserToDelete(null);
                  });
                });
              }
            }}
          >
            წაშლა
          </Button>
        </Modal.Footer>
        )}
      </Modal>

    </div>
  );
}

export default OrderList;
