/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import texts from '../../lib/texts';
import utils from '../../lib/utils';

import {
  useShowLoginModal, useLastNotification, useUser, useCookieAccepted,
} from '../../lib/atoms';
import { onNotification } from '../../firebase';
import CookieModal from '../../pages/loginModal/CookieModal';

const lang = 'ka';

function SiteHeader() {
  const [cookie] = useCookieAccepted();
  const [, setShowLoginModal] = useShowLoginModal();
  const { user } = useUser();
  const [collapse, setCollapse] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [, setLastNotif] = useLastNotification();

  const closeNotification = (index) => {
    const newNot = [...notifications];
    newNot.splice(index, 1);
    setNotifications(newNot);
  };

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = onNotification(user?.uid, (doc) => {
        if (doc?.timestamp > Date.now() - 60 * 1000) {
          setLastNotif(doc);
          setNotifications((oldArray) => [doc, ...oldArray]);
        }
      });
      return () => unsubscribe();
    }
    return () => { };
  }, [user]);

  return (
    <>
      {!cookie && <CookieModal />}
      <ToastContainer className="p-3" position="top-start" style={{ position: 'absolute', zIndex: 10000 }}>
        {notifications.map((val, index) => (
          <Toast onClose={() => closeNotification(index)} bg="warning">
            <Toast.Header>
              <strong className="me-auto">
                <Link to={`/order/${val.orderId}`} className="nav-link text-black">{utils.readableId(val.orderId)}</Link>
              </strong>
            </Toast.Header>
            <Toast.Body>{val.status}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
      <header
        id="header"
        data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 54, 'stickySetTop': '-54px', 'stickyChangeLogo': false}"
        style={{ height: '110px' }}
      >
        <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0" style={{ backgroundColor: '#033a5c' }}>
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column w-100">
                <div className="header-row justify-content-between">
                  <div className="header-logo z-index-2 col-lg-2 px-0">
                    <a href="/">
                      <img
                        alt="SOS"
                        // width={123}
                        height={65}
                        data-sticky-width={82}
                        data-sticky-height={40}
                        data-sticky-top={84}
                        src="/img/sos/LOGO1.svg"
                      />
                    </a>
                  </div>
                  <div className="header-nav header-nav-links justify-content-end pe-lg-4 me-lg-3">
                    <div className="header-nav-main header-nav-main-arrows header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1">
                      <nav className={collapse ? 'collapse' : ''}>
                        <ul className="nav nav-pills" id="mainNav">
                          <li>
                            {/* <Link to="/" className="nav-link active"> */}
                            <Link to="/services" className=" nav-link text-3 text-light text-light">
                              {texts.navServices[lang]}
                            </Link>
                          </li>
                          <li>
                            <Link to="/about" className=" nav-link text-3 text-light text-light">
                              {texts.navAboutUs[lang]}
                            </Link>
                          </li>
                          <li>
                            <Link to="/clients" className=" nav-link text-3 text-light text-light">
                              {texts.navForClients[lang]}
                            </Link>
                          </li>
                          <li>
                            <Link to="/partners" className=" nav-link text-3 text-light text-light">
                              {texts.navForPartners[lang]}
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact" className=" nav-link text-3 text-light text-light">
                              {texts.navContact[lang]}
                            </Link>
                          </li>
                          {!user && true && ( // TODO
                            <li>
                              <a id="login-icon" href="#" className=" nav-link text-3 text-light">
                                <i
                                  className="text-light d-none d-lg-block d-xl-block icons icon-user font-weight-bold text-4 text-color-dark position-relative top-3 text-color-hover-primary text-decoration-none mb-2"
                                  onClick={() => setShowLoginModal(true)}
                                />
                                <span className="d-block d-lg-none d-xl-none" onClick={() => setShowLoginModal(true)}>
                                  {texts.navLogin[lang]}
                                </span>
                              </a>
                            </li>
                          )}
                          { user && true && ( // TODO
                            <li>
                              <Link to="/profile" className=" nav-link text-3 text-light">
                                <i
                                  className="text-light d-none d-lg-block d-xl-block icons icon-user font-weight-bold text-4 text-color-dark position-relative top-3 text-color-hover-primary text-decoration-none mb-2"
                                />
                                <span className="d-block d-lg-none d-xl-none">
                                  {texts.navProfile[lang]}
                                </span>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <button
                    className="btn header-btn-collapse-nav ms-4"
                    data-bs-toggle="collapse"
                    data-bs-target=".header-nav-main nav"
                    onClick={() => setCollapse(!collapse)}
                  >
                    <i className="fas fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default SiteHeader;
