/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
import ServicesPage from './pages/homePage/ServicesPage';
import TyrePage from './pages/homePage/TyrePage';
import PartnersPage from './pages/homePage/PartnersPage';
import ClientsPage from './pages/homePage/ClientsPage';
import ContactPage from './pages/homePage/ContactPage';
import AboutUs from './pages/homePage/AboutUs';
import ProfilePage from './pages/profilePage/ProfilePage';
import CreateOrderPage from './pages/orderPage/CreateOrderPage';
import OrderPage from './pages/orderPage/OrderPage';

function Scroller() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div />;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Scroller />
        <Routes>
          <Route path="/tow" element={<TyrePage service="tow" />} />
          <Route path="/tyre" element={<TyrePage service="tyre" />} />
          <Route path="/battery" element={<TyrePage service="battery" />} />
          <Route path="/sober" element={<TyrePage service="sober" />} />
          <Route path="/fuel" element={<TyrePage service="fuel" />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/profile" element={<ProfilePage section="profile" />} />
          <Route path="/my-services" element={<ProfilePage section="services" />} />
          <Route path="/services-map" element={<ProfilePage section="servicesMap" />} />
          <Route path="/order-list" element={<ProfilePage section="orders" />} />
          <Route path="/payment-methods" element={<ProfilePage section="paymentMethods" />} />
          <Route path="/verification-requests" element={<ProfilePage section="verificationRequests" />} />
          <Route path="/verified-services" element={<ProfilePage section="verifiedServices" />} />
          <Route path="/user-list" element={<ProfilePage section="userList" />} />
          <Route path="/service-review/:id" element={<ProfilePage section="serviceReview" />} />
          <Route path="/order" element={<CreateOrderPage />} />
          <Route path="/order/:id" element={<OrderPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
