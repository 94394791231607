/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderPage.css';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import apiCall from '../../api';
import classNames from '../../lib/classNames';
import enums from '../../lib/enums';
import texts from '../../lib/texts';
import { useUser } from '../../lib/atoms';
import consts from '../../lib/consts';
import maps from '../../lib/maps';

const twoLocationProblems = [
  enums.problemType.SOBER,
  enums.problemType.TOW,
  enums.problemType.TOW_SPIDER,
];

function CreateOrderPage() {
  const lang = 'ka';

  const { user, userType } = useUser();
  const navigate = useNavigate();

  const [problemType, setProblemType] = useState(enums.problemType.TOW_SPIDER);
  const [comment, setComment] = useState('');

  const [center, setCenter] = useState({
    lat: 41.7131688,
    lng: 44.7826625,
  });

  const [marker, setMarker] = useState({
    lat: 41.7131688,
    lng: 44.7826625,
  });

  const [markerTo, setMarkerTo] = useState({
    lat: 41.7131688,
    lng: 44.7826625 + 0.0005,
  });

  const createOrderRequest = async () => {
    try {
      const data = {
        locFrom: marker,
        ...(twoLocationProblems.includes(problemType) ? { locTo: markerTo } : {}),
        problemType,
        comment,
        uid: user.uid,
        userType,
        idToken: user.accessToken,
      };
      const order = await apiCall('/create-order', data);
      console.log(order);
      if (order?.status === enums.statuses.OK) {
        navigate(`/order/${order.orderId}`);
      }
    } catch (error) {
      // TODO: show user that something is wrong
      console.error('Error getting service!', error);
    }
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const mapClicked = (event) => {
    console.log(event.latLng.lat(), event.latLng.lng());
  };

  const markerClicked = (marker) => {
    console.log(marker);
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setMarker({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setMarkerTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude + 0.0005,
      });
    });
  };

  const iconC = {
    url: '/img/sos/car.png',
    scaledSize: { width: 40, height: 40 },
  };

  const iconH = {
    url: '/img/sos/home.png',
    scaledSize: { width: 40, height: 40 },
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: consts.MAPS_API,
  });

  return (
    <div>
      <SiteHeader />

      <div role="main" className={classNames.MAIN}>
        <section className="page-header page-header-modern bg-color-light-scale-1 page-header-lg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center p-static order-2 text-center">
                <h1 className="font-weight-bold text-dark">Make Order</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container pt-3 pb-2">
          <div className="row pt-2">
            <div className="col-lg-12">
              <form role="form" className="needs-validation">
                {isLoaded
                  && (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={17}
                    onClick={mapClicked}
                    options={{ styles: maps.streetStyle }}
                  >
                    <MarkerF
                      position={marker}
                      icon={iconC}
                      draggable
                      onDragEnd={(event) => setMarker({
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                      })}
                      onClick={() => markerClicked('AAAA')}
                    />
                    { twoLocationProblems.includes(problemType) && (
                    <MarkerF
                      position={markerTo}
                      icon={iconH}
                      draggable
                      onDragEnd={(event) => setMarkerTo({
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                      })}
                      onClick={() => markerClicked('BBBB')}
                    />
                    )}
                  </GoogleMap>
                  )}

                <div className="form-group row">
                  <div className="form-group col-lg-9" />
                  <div className="form-group col-lg-3">
                    <button className="btn btn-primary btn-modern float-end" data-loading-text="Loading..." onClick={(event) => { event.preventDefault(); getLocation(); }}>navigate</button>
                  </div>
                </div>

                <div className={classNames.FROM_GROUP_ROW}>
                  <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">Problem type</label>
                  <div className={classNames.COL_LG_9}>
                    <select className="form-control text-3 h-auto py-2" value={problemType || ''} onChange={(e) => setProblemType(e.target.value)}>
                      {Object.entries(enums.problemType).map(([, value]) => (
                        <option key={value} value={value}>{texts.problemTypes[value][lang]}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">Comment</label>
                  <div className="col-lg-9">
                    <input className="form-control text-3 h-auto py-2" type="txt" name="comment" value={comment || ''} required onChange={(e) => setComment(e.target.value)} disabled={userType === 'company'} />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="form-group col-lg-12">
                    <input type="submit" defaultValue="Save" className="btn btn-primary btn-modern float-end" data-loading-text="Loading..." onClick={(event) => { event.preventDefault(); createOrderRequest(); }} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default CreateOrderPage;
