/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
// import services from './services';
import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const [showLoginModal] = useShowLoginModal();

  // const { service } = props;

  return (
    <div
      className="body"
      style={{
        backgroundImage: 'url(img/sos/part.jpeg)',
        backgroundSize: '100vw',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#212529',
        backgroundPositionY: '120px',

      }}
    >
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div className="" style={{ textAlign: 'center', marginTop: '60px', padding: '10px' }}>
        <h2
          style={{
            color: 'white',
            // textShadow: '-1px -1px 0 #053a5c,1px -1px 0 #053a5c,-1px 1px 0 #053a5c,1px 1px 0 #053a5c',
            fontSize: 'max(2.5vw, 22px)',
            whiteSpace: 'nowrap',
          }}
          className="font-weight-bold"
        >
          გახდი SOS AUTO ს პარტნიორი
        </h2>
        <div className="row">
          <div className="col-lg-3 col-1" />
          <div className="col-lg-6 col-10">
            <p className="font-weight-bold text-light" style={{ fontSize: '16px', marginTop: '60px' }}>
              SOS AUTO-ს აპლიკაცია იმისთვის შეიქმნა, რომ თქვენ უფრო სწრაფად განავითაროთ ბიზნესი და გქონდეთ მეტი შემოსავალი, ეს კი კმაყოფილი მომხმარებლის ხარჯზე მოხდება.
            </p>
          </div>
          <div className="col-lg-3 col-1" />
        </div>
        <div className="row" style={{ marginTop: '70px' }}>
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      მეტი სამუშაო თქვენს მახლობლად
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      SOS AUTO-ს აპლიკაციის საშუალებით ჩვენ დაგაკავშირებთ ისეთ მომხმარებელთან,  რომელიც თქვენთან ფიზიკურად ყველაზე ახლოს იქნება.
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      ამგვარად, SOS AUTO-ს აპლიკაციით დაზოგავთ დროს, საწვავს და გექნებათ მეტი შემოსავალი.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      ანაზღაურების მიღება უსწრაფესად

                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      სამუშაოს შესრულებიდან სულ რამდენიმე წუთში მიიღებთ ანაზღაურებას, ჩვენ არ დაგვჭირდება რამდენიმე საათი, დღე ან კვირა.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      უფასო სერვისები მხოლოდ თქვენთვის
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      თქვენზე ზრუნვა ჩვენი პრიორიტეტია.
                      ჩვენთან პარტნიორობის შემთხვევაში მიიღებთ:
                      <br />
                      უფასო ავტოსერვისებს თქვენი ავტომობილისთვის, ფასდაკლების ვაუჩერებს საწვავზე, ავტონაწილებზე, მანქანის რეცხვაზე და ა.შ
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <img
                      style={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '15px',
                        marginTop: '-7px',
                      }}
                      src="img/sos/checked.png"
                      alt=""
                    />
                  </div>
                  <div className="col-10" style={{ textAlign: 'left' }}>
                    <h5 style={{ color: '#fec50c', fontSize: '20px', lineHeight: '1.5' }}>
                      უფასო გაწევრიანება და არანაირი რისკი SOS AUTO_ში
                    </h5>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      გგახდით დამოუკიდებელი სერვისის მიმწოდებელი ახლავე!
                      უფასოდ შეუერთდით SOS AUTO-ს ქსელს.
                      დარეგისტრირდით დღესვე და გამოიმუშავეთ უფრო მეტი თანხა.
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      დარეგისტრირდით და დაიწყეთ შეკვეთების მიღება იმავე დღეს.
                    </p>
                    <p className="font-weight-bold text-light" style={{ fontSize: '16px' }}>
                      ჩვენგან გექნებათ 24-საათიანი აქტიური სამუშაო მხარდაჭერა!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1" />
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
