import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  getFirestore, doc, setDoc, onSnapshot,
} from 'firebase/firestore';

import consts from './lib/consts';

const firebaseConfig = {
  apiKey: 'AIzaSyAzw-ReAU07GYM49LiJb5wyN3WNGJgZXe0',
  authDomain: 'peakit2-test.firebaseapp.com',
  projectId: 'peakit2-test',
  storageBucket: 'peakit2-test.appspot.com',
  messagingSenderId: '164811529415',
  appId: '1:164811529415:web:5e9b25325efd57768ef2e2',
  measurementId: 'G-KQ6K4LJHEV',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const onNotification = (uid, callback) => onSnapshot(
  doc(db, 'db', consts.SOS_DB, 'notifications', uid),
  (d) => {
    setDoc(doc(db, 'db', consts.SOS_DB, 'notifications', uid), {});
    callback(d.data());
  },
);

export {
  auth,
  logInWithEmailAndPassword,
  logout,
  onNotification,
};
