/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
// import services from './services';
import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const [showLoginModal] = useShowLoginModal();

  // const { service } = props;

  return (
    <div
      className="body"
      style={{
        backgroundImage: 'url(img/sos/contact.jpg)',
        backgroundSize: 'min(100vw, 1000px)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#212529',
        backgroundPosition: '50% 110px',
      }}
    >
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div className="" style={{ textAlign: 'center', marginTop: '60px', padding: '10px' }}>
        <h2
          style={{

            // textShadow: '-1px -1px 0 #053a5c,1px -1px 0 #053a5c,-1px 1px 0 #053a5c,1px 1px 0 #053a5c',
            fontSize: 'max(2.5vw, 30px)',
            whiteSpace: 'nowrap',
            color: '#fec50c',
          }}
          className="font-weight-bold"
        >
          კონტაქტი
        </h2>
        <div className="row">
          <div className="col-md-2 col-lg-3 col-xl-4" />
          <div
            className="col-md-8 col-lg-6 col-xl-4"
            style={{
              textAlign: 'left',
              fontSize: '25px',
              lineHeight: '2.5',
              color: 'white',
              fontWeight: 'bold',
              padding: '50px',
            }}
          >
            <div>
              <i className="fa fa-phone" style={{ color: '#fec50c' }} />
              {' '}
              &nbsp;
              +995 577 00 00 68
            </div>
            <div>
              <i className="fa fa-envelope" style={{ color: '#fec50c' }} />
              {' '}
              &nbsp;
              info@sosauto.ge
            </div>
            <div>
              <i className="fa fa-calendar" style={{ color: '#fec50c' }} />
              {' '}
              &nbsp;
              ორშ-კვირა  24/7ზე
            </div>

          </div>
          <div className="col-lg-4" />
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
