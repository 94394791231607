/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ReactGA from 'react-ga4';
// import TagManager from 'react-gtm-module';
import App from './App';

ReactGA.initialize('G-C537RP42BX');
ReactGA.send(window.location.pathname);

// TagManager.initialize({
//   gtmId: 'AW-16648704119',
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
