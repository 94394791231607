/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';
// import services from './services';
import { useShowLoginModal } from '../../lib/atoms';

function Main() {
  const [showLoginModal] = useShowLoginModal();

  // const { service } = props;

  return (
    <div
      className="body"
      style={{
        // backgroundImage: 'url(img/sos/part.jpeg)',
        // backgroundSize: '100vw',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: '#212529',
        // backgroundPositionY: '120px',

      }}
    >
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div className="" style={{ textAlign: 'center', marginTop: '60px', padding: '10px' }}>
        <h2
          style={{
            color: 'white',
            // textShadow: '-1px -1px 0 #053a5c,1px -1px 0 #053a5c,-1px 1px 0 #053a5c,1px 1px 0 #053a5c',
            fontSize: 'max(1.5vw, 18px)',
            whiteSpace: 'nowrap',
          }}
          className="font-weight-bold"
        >
          <span style={{ color: '#fec50c' }}>SOS AUTO</span>
          {' '}
          სასწრაფო საგზაო დახმარების
          <br />
          აპლიკაცია შენი ავტომობილისთვის
        </h2>
        <div className="row">
          <div className="col-sm-1 col-lg-3 col-xl-4" />
          <div className="col-sm-10 col-lg-3 col-xl-4">
            <div
              className="body"
              style={{
                width: '100%',
                aspectRatio: 1.8,
                backgroundImage: 'url(img/sos/clients.svg)',
                backgroundSize: '102% 102%',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#212529',
                backgroundPosition: '34% 56%',
              }}
            />
            {/* <img src="img/sos/clients.svg" alt="My Happy SVG" style={{ borderStyle: 'none' }} /> */}
          </div>
          <div className="col-lg-3 col-1" />
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
