/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import './HomePage.css';
// import { Link } from 'react-router-dom';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import LoginModal from '../loginModal/LoginModal';

// import texts from '../../lib/texts';

import { useShowLoginModal } from '../../lib/atoms';

// const lang = 'ka';

function Main() {
  const [showLoginModal] = useShowLoginModal();

  return (
    <div className="body" style={{ backgroundColor: '#212529' }}>
      <SiteHeader />

      {showLoginModal && <LoginModal />}

      <div role="main" className="main">
        <div className="container my-5 pt-md-4 pt-xl-0">
          <div className="row align-items-center justify-content-center pb-4 mb-5">
            <div className="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
              <div style={{ textAlign: 'center' }} className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}" style={{ width: 'fit-content', display: 'inline-block', textAlign: 'left' }}>
                  <h1 className="font-weight-bold text-10" style={{ color: '#fec50e' }}>ჩვენს შესახებ</h1>
                  <p className="font-weight-bold text-5 text-light">
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    {' '}
                    არის მობილური აპლიკაცია ევაკუატორის და ავტოასისტანსის გამოსაძახებლად.
                  </p>
                  <p className="text-5 text-light">
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    -ს აპლიკაცია მომხმარებელს სრულიად აარიდებს ავტოდახმარების მოძიებისა და ლოდინის სირთულეს.
                  </p>
                  <p className=" text-5 text-light">
                    ჩვენი აპლიკაციის საშუალებით დახმარების გამოძახება ხდება მარტივად და სწრაფად. სწორედ ამიტომ
                    {' '}
                    <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                    -მ გაიმარჯვა Stasrtup Drive-ის პროექტში და თეგეტა ჰოლდინგისგან მიიღო ინვესტიცია.

                  </p>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center' }} className="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation animated fadeInRightShorterPlus appear-animation-visible" data-appear-animation="fadeInRightShorterPlus" data-appear-animation-delay={1450} data-plugin-options="{'accY': -200}">
              <div className="position-relative">
                <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
                  <img src="img/sos/about.jpg" style={{ maxHeight: '495px', width: '100%', objectFit: 'contain' }} alt="" />
                </div>
              </div>
            </div>
            <div className="col-12  align-items-center" style={{ marginTop: '30px' }}>
              <div style={{ textAlign: 'center' }} className="position-relative">

                <p className="font-weight-bold text-7 text-light ">
                  გადმოწერე
                  {' '}
                  <span style={{ color: '#fec50e' }}>SOS AUTO</span>
                  -ს აპლიკაცია და იმგზავრე მშვიდად!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Main;
