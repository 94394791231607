/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ProfilePage.css';

import apiCall from '../../api';

import { useUser } from '../../lib/atoms';

function UserInfo() {
  // const [user, loading, error] = useAuthState(auth);
  const { user, userType } = useUser();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [nid, setNid] = useState('');
  const [iban, setIban] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(true);
      apiCall(
        '/get-user-info',
        {
          uid: user.uid,
          idToken: user.accessToken,
        },
      ).then((response) => {
        setPhoneNumber(response.phone);
        setEmail(response.email);
        setFirstName(response.firstname);
        setLastName(response.lastname);
        setIban(response.iban);
        setNid(response.nid);
        setLoading(false);
      }).catch(console.log);
    }
  }, [user]);

  const updateUserRequest = () => {
    setSubmitModalOpen(false);
    setLoading(true);
    apiCall(
      '/update-user-info',
      {
        email,
        iban,
        nid,
        firstname: firstName,
        lastname: lastName,
        uid: user.uid,
        idToken: user.accessToken,
      },
    ).then((r) => {
      setLoading(false);
      console.log(r);
    }).catch(console.log);
  };

  return (
    <div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
          First name
        </label>
        <div className="col-lg-9">
          <input
            className="form-control text-3 h-auto py-2  bg-dark border border-light"
            type="text"
            name="firstName"
            value={firstName || ''}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
          Last name
        </label>
        <div className="col-lg-9">
          <input
            className="form-control text-3 h-auto py-2  bg-dark border border-light"
            type="text"
            name="lastName"
            value={lastName || ''}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
          National/Company identification
        </label>
        <div className="col-lg-9">
          <input
            className="form-control text-3 h-auto py-2  bg-dark border border-light"
            type="text"
            name="lastName"
            value={nid || ''}
            onChange={(e) => setNid(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
          Email
        </label>
        <div className="col-lg-9">
          <input
            className="form-control text-3 h-auto py-2  bg-dark border border-light"
            type="email"
            name="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            disabled={userType === 'company'}
          />
        </div>
      </div>

      {phoneNumber && (
        <div className="form-group row">
          <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
            Phone
          </label>
          <div className="col-lg-9">
            <input
              className="form-control text-3 h-auto py-2  bg-dark border border-light"
              value={phoneNumber}
              disabled
            />
          </div>
        </div>
      )}

      {['company', 'worker'].includes(userType) && (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 text-light">
          IBAN
        </label>
        <div className="col-lg-9">
          <input
            className="form-control text-3 h-auto py-2  bg-dark border border-light"
            type="email"
            name="email"
            value={iban || ''}
            onChange={(e) => setIban(e.target.value)}
          />
        </div>
      </div>
      )}

      <div className="form-group row">
        <div className="form-group col-lg-9" />
        <div className="form-group col-lg-3">
          <input
            type="submit"
            value="Save"
            className={`btn btn-primary btn-modern float-end ${loading ? 'disabled' : ''}`}
            data-loading-text="Loading..."
            onClick={(event) => {
              event.preventDefault();
              setSubmitModalOpen(true);
            }}
          />
        </div>
      </div>

      <Modal show={submitModalOpen} onHide={() => setSubmitModalOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <Button variant="danger" onClick={() => setSubmitModalOpen(false)}>
            No
          </Button>
          <Button
            variant="success"
            onClick={updateUserRequest}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserInfo;
