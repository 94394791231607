/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import apiCall from '../../api';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import texts from '../../lib/texts';
import utils from '../../lib/utils';
import consts from '../../lib/consts';

const mapUserTypeOrderRole = {
  [usersTypes.CLIENT]: 'clientId',
  [usersTypes.WORKER]: 'driverId',
  [usersTypes.DRIVER]: 'driverId',
  [usersTypes.COMPANY]: 'serviceOwnerId',
};

function OrderList() {
  const lang = 'ka';
  const { user, userType } = useUser();
  const bottomEl = useRef(null);

  const [orders, setOrders] = useState([]);
  const [startAfter, setStartAfter] = useState(undefined);
  const [loadMoreText, setLoadMoreText] = useState('Load more');
  const [reportUrl, setReportUrl] = useState('');

  const loadText = 'Load more';
  const loadingText = 'Loading...';
  const loadMore = () => {
    if (loadMoreText === loadText && startAfter) {
      setLoadMoreText(loadingText);
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
      apiCall('/get-orders-all', {
        idToken: user.accessToken,
        startAfter,
      }).then((response) => {
        if (response.length > 0) {
          setOrders([...orders, ...response]);
          setStartAfter(response[response.length - 1].id);
          setLoadMoreText(loadText);
        } else {
          setLoadMoreText('No more');
        }
        setTimeout(() => bottomEl?.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
      });
    }
  };

  useEffect(() => {
    if (user?.uid && userType) {
      if (userType === usersTypes.SUPER) {
        // TODO filters
        apiCall('/get-orders-all', { idToken: user.accessToken }).then((response) => {
          console.log(response);
          if (response.length > 0) {
            setOrders(response);
            setStartAfter(response[response.length - 1].id);
          }
        });
      } else {
        const data = {
          role: mapUserTypeOrderRole[userType],
          idToken: user.accessToken,
        };
        apiCall('/get-orders', data).then((response) => {
          if (response?.orders) {
            setOrders(response.orders);
          }
        });
      }
    }
  }, [user?.uid, userType]);

  const handleDateRangePickerCallback = (start, end) => {
    setReportUrl(`${consts.BACKEND_URL}/download-report?from=${start.valueOf()}&to=${end.valueOf()}`);
  };

  const downloadReport = () => {
    apiCall('/get-one-time-token', { idToken: user.accessToken }).then((response) => {
      window.open(`${reportUrl}&token=${response.token}`, '_blank');
    });
  };

  return (
    <div>

      {userType === usersTypes.CLIENT && (
        <section className="container pt-3 pb-2 text-center">
          <Link to="/order" className="btn btn-rounded btn-primary btn-modern">
            New Order
          </Link>
        </section>
      )}

      {userType === usersTypes.SUPER && (
        <section className="container pt-3 pb-2 text-center">
          <DateRangePicker onCallback={handleDateRangePickerCallback}>
            <input style={{ minWidth: '200px', marginRight: '20px' }} />
          </DateRangePicker>
          <a href="#" className="btn btn-rounded btn-primary btn-modern" onClick={downloadReport}>
            Download Report
          </a>
        </section>
      )}

      <div className="container pt-3 pb-2">
        <div className="row pt-2">
          <div className="col-lg-12">
            <table className="table table-striped  text-light">
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    price
                  </th>
                  {userType === usersTypes.SUPER && (
                    <th>
                      driver phone
                    </th>
                  )}
                  {userType === usersTypes.SUPER && (
                  <th>
                    client phone
                  </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {orders.map((o) => (
                  <tr>
                    <td style={{ color: 'white' }}>
                      <Link to={`/order/${o.id}`} className="nav-link text-light">{utils.readableId(o.id)}</Link>
                    </td>
                    <td style={{ color: 'white' }}>
                      {new Date(o.created).toLocaleString()}
                    </td>
                    <td style={{ color: 'white' }}>
                      {texts.problemTypes[o.problemType][lang]}
                    </td>
                    <td style={{ color: 'white' }}>
                      {o.status}
                    </td>
                    <td style={{ color: 'white' }}>
                      {o.tx?.amount}
                    </td>
                    {userType === usersTypes.SUPER && (
                    <td style={{ color: 'white' }}>
                      {o.driverPhone}
                    </td>
                    )}
                    {userType === usersTypes.SUPER && (
                      <td style={{ color: 'white' }}>
                        {o.userPhone}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {startAfter && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <a href="#" ref={bottomEl} className="btn btn-rounded btn-primary btn-modern" onClick={loadMore}>
                {loadMoreText}
              </a>
            )}
          </div>
        </div>
      </div>

    </div>
  );
}

export default OrderList;
