/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import './ProfilePage.css';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../../firebase';
import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';
import UserInfo from './UserInfo';
import Services from './Services';
import ServicesMap from './ServicesMap';
import OrderList from './OrderList';
import PaymentMethods from './PaymentMethods';
import VerificationRequests from './VerificationRequests';
import VerifiedServices from './VerifiedServices';
import UserList from './UserList';
import ServiceReview from './ServiceReview';
import classNames from '../../lib/classNames';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import texts from '../../lib/texts';

const userTypeConfig = {
  [usersTypes.CLIENT]: {
    profile: true,
    orders: true,
    paymentMethods: true,
  },
  [usersTypes.DRIVER]: {
    profile: true,
    services: true,
    orders: true,
  },
  [usersTypes.WORKER]: {
    profile: true,
    services: true,
    orders: true,
  },
  [usersTypes.COMPANY]: {
    showEmail: true,
    profile: true,
    services: true,
    servicesMap: true,
    orders: true,
  },
  [usersTypes.SUPER]: {
    showEmail: true,
    profile: true,
    orders: true,
    verificationRequests: true,
    verifiedServices: true,
    userList: true,
    servicesMap: true,
  },
};

function Profile(props) {
  const lang = 'ka';

  const { section: profileSection } = props;

  const { user, userType, userPhone } = useUser();

  const navigate = useNavigate();

  return (
    <div style={{ overflowX: 'auto' }}>
      <SiteHeader />

      <div role="main" className={classNames.MAIN}>
        <section className="page-header page-header-modern bg-color-dark-scale-1 page-header-md ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center p-static order-2 text-center">
                <h1 className="font-weight-bold text-light">
                  {texts.profilePageHeaders[profileSection][lang]}
                </h1>
              </div>
            </div>
          </div>
        </section>

        <div className="container pt-3 pb-2">
          <div className="row pt-2">
            <div className="col-lg-3 mt-4 mt-lg-0">
              {/* <div className="d-flex justify-content-center mb-4">
                <div className="profile-image-outer-container">
                  <div className="profile-image-inner-container bg-color-primary">
                    <img src="img/avatars/avatar.jpg" />
                    <span className="profile-image-button bg-color-dark">
                      <i className="fas fa-camera text-light" />
                    </span>
                  </div>
                  <input
                    type="file"
                    id="file"
                    className="form-control profile-image-input"
                  />
                </div>
              </div> */}
              <aside className="sidebar mt-2" id="sidebar">
                <ul className="nav nav-list flex-column mb-5">
                  <li className="nav-item" style={{ marginLeft: '20px' }}>
                    {userTypeConfig[userType].showEmail ? user?.email : userPhone}
                  </li>
                  {userTypeConfig[userType].profile && (
                    <li className="nav-item">
                      <Link to="/profile" className="nav-link text-3 text-light">
                        {texts.profilePageHeaders.profile[lang]}
                      </Link>
                    </li>
                  )}
                  {userTypeConfig[userType].services && (
                    <li className="nav-item">
                      <Link to="/my-services" className="nav-link text-3 text-light">
                        {texts.profilePageHeaders.services[lang]}
                      </Link>
                    </li>
                  )}

                  {userTypeConfig[userType].servicesMap && (
                  <li className="nav-item">
                    <Link to="/services-map" className="nav-link text-3 text-light">
                      {texts.profilePageHeaders.servicesMap[lang]}
                    </Link>
                  </li>
                  )}

                  {/* <li className="nav-item"><a className="nav-link text-3" href="#">{translations.profile.sidebar.BILLING[userLanguage]}</a></li> */}
                  {/* <li className="nav-item"><a className="nav-link text-3" href="#">{translations.profile.sidebar.INVOICES[userLanguage]}</a></li> */}
                  {userTypeConfig[userType].orders && (
                    <li className="nav-item">
                      <Link to="/order-list" className="nav-link text-3 text-light">
                        {texts.profilePageHeaders.orders[lang]}
                      </Link>
                    </li>
                  )}
                  {userTypeConfig[userType].paymentMethods && (
                    <li className="nav-item">
                      <Link to="/payment-methods" className="nav-link text-3 text-light">
                        {texts.profilePageHeaders.paymentMethods[lang]}
                      </Link>
                    </li>
                  )}

                  {userTypeConfig[userType].verificationRequests && (
                    <li className="nav-item">
                      <Link to="/verification-requests" className="nav-link text-3 text-light">
                        {texts.profilePageHeaders.verificationRequests[lang]}
                      </Link>
                    </li>
                  )}

                  {userTypeConfig[userType].verifiedServices && (
                  <li className="nav-item">
                    <Link to="/verified-services" className="nav-link text-3 text-light">
                      {texts.profilePageHeaders.verifiedServices[lang]}
                    </Link>
                  </li>
                  )}

                  {userTypeConfig[userType].userList && (
                  <li className="nav-item">
                    <Link to="/user-list" className="nav-link text-3 text-light">
                      {texts.profilePageHeaders.userList[lang]}
                    </Link>
                  </li>
                  )}

                  <li className="nav-item">
                    <a
                      className="nav-link text-3"
                      href="#"
                      style={{ color: 'red' }}
                      onClick={(event) => {
                        event.preventDefault();
                        navigate('/');
                        logout();
                      }}
                    >
                      {texts.profilePageHeaders.logout[lang]}
                    </a>
                  </li>
                </ul>
              </aside>
            </div>

            <div className="col-lg-9 row">
              {profileSection === 'profile' && <UserInfo />}
              {profileSection === 'services' && <Services />}
              {profileSection === 'servicesMap' && <ServicesMap />}
              {profileSection === 'orders' && <OrderList />}
              {profileSection === 'paymentMethods' && <PaymentMethods />}
              {profileSection === 'verificationRequests' && <VerificationRequests />}
              {profileSection === 'verifiedServices' && <VerifiedServices />}
              {profileSection === 'serviceReview' && <ServiceReview />}
              {profileSection === 'userList' && <UserList />}
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </div>
  );
}

export default Profile;
