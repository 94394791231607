/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
export default {
  tyre: {
    title: 'საბურავის გამოცვლა',
    color: '#fff',
    img: 'img/sos/flat.jpg',
    text: () => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#fec50c', margin: '30px' }}> SOS AUTO-ს აპლიკაციით გამოიძახეთ თქვენთან ყველაზე ახლოს მდებარე ავტოდახმარების სერვისები. </h4>
        </div>
        <p className="font-weight-bold text-light">
          პირველად საქართველოში  SOS AUTO-ს აპლიკაცია - ,,სასწრაფო დახმარება თქვენი  ჩვენ შევქმენით ვებ და მობილური აპლიკაცია სერვისის შემსრულებლებისა და მომხმარებლების დასაკავშირებლად. დაივიწყეთ ავტოდახმარების ძიებისა და ლოდინის სირთულე, გადმოწერეთ აპლიკაცია და მიიღეთ სასურველი დახმარება
        </p>
        <p className="font-weight-bold text-light">
          მოითხოვეთ საბურავის შეცვლის სერვისი მარტივად, სწრაფად და ხარისხიანად!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          როგორ მუშაობს?
        </h5>
        <p className="font-weight-bold text-light">
          თუ გზაზე საბურავი გაგიფუჭდათ, ნუ ინერვიულებთ, SOS AUTO-ს აპლიკაციით მარტივად შეძლებთ დახმარების გამოძახებას.
        </p>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაციით თქვენ შეგიძლიათ რეალურ დროში თვალყური ადევნოთ მძღოლის
          <br />
          გადაადგილებას და ზუსტად გეცოდინებათ, თუ რა დრო დასჭირდება დახმარებას თქვენამდე მოსასვლელად.
        </p>
        <p className="font-weight-bold text-light">
          პროფესიონალი და სანდო ავტოასისტენტები
          <br />
          ჩვენ ვთანამშრომლობთ პროფესიონალ და სანდო ავტოასისტენტებთან.
          <br />
          დარწმუნებული იყავით, რომ SOS AUTO-სთან ერთად თქვენ და თქვენი მანქანა საიმედო ხელში იქნებით!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          გამჭვირვალე ფასი და გადახდა
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაცია ფასს დაგითვლით კილომეტრაჟის მიხედვით, გადახდა კი შესაძლებელი იქნება როგორც გადარიცხვით, ასევე ნაღდი ანგარიშსწორებით.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          ხელმისაწვდომობა 24/7-ზე
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენი სერვისები ხელმისაწვდომია საქართველოს მასშტაბით, დღე-ღამის ნებისმიერ დროს, 24/7-ზე.
        </p>
        <p className="font-weight-bold text-light">
          ჩვენ აქ ვართ, რათა დაგეხმაროთ მაშინ, როცა ეს ყველაზე მეტად გჭირდებათ!
          <br />
          თქვენი კმაყოფილება ჩვენთვის პრიორიტეტულია!
        </p>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, რა იცით რა ხდება გზაზე!
        </p>
      </div>
    ),
  },
  battery: {
    title: 'აკუმულატორის დატენვა',
    color: '#fff',
    img: 'img/sos/battery.jpeg',
    text: () => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#fec50c', margin: '30px' }}>SOS AUTO-ს აპლიკაციით გამოიძახეთ თქვენთან ყველაზე ახლოს მდებარე ავტოდახმარების სერვისები. </h4>
        </div>
        <p className="font-weight-bold text-light">
          პირველად საქართველოში SOS AUTO-ს აპლიკაცია - ,,სასწრაფო დახმარება თქვენი
          ავტომობილისთვის“. ჩვენ შევქმენით ვებ და მობილური აპლიკაცია სერვისის
          შემსრულებლებისა და მომხმარებლების დასაკავშირებლად.
        </p>
        <p className="font-weight-bold text-light">
          დაივიწყეთ ავტოდახმარების ძიებისა და ლოდინის სირთულე, გადმოწერეთ აპლიკაცია
          და მიიღეთ სასურველი დახმარება.
        </p>
        <p className="font-weight-bold text-light">
          მოითხოვეთ აკუმულატორის დატენვის სერვისი მარტივად, სწრაფად და ხარისხიანად!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          როგორ მუშაობს?
        </h5>
        <p className="font-weight-bold text-light">
          თუ გზაზე თქვენს მანქანას „დაუჯდა“ აკუმულატორი, ნუ ინერვიულებთ, SOS AUTO-ს
          აპლიკაციიდან გამოიძახეთ ,,აკუმულატორის დატენვის სერვისი“ და უმოკლეს დროში
          მიიღეთ დახმარება.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          რეალურ დროში თვალყურის დევნება და განახლებები
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაციით თქვენ შეგიძლიათ რეალურ დროში თვალყური ადევნოთ
          მძღოლის გადაადგილებას და ზუსტად გეცოდინებათ, თუ რა დრო დასჭირდება
          დახმარებას თქვენამდე მოსასვლელად.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          პროფესიონალი და სანდო ავტოასისტენტები
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენ ვთანამშრომლობთ პროფესიონალ და სანდო ავტოასისტენტებთან.
          <br />
          დარწმუნებული იყავით, რომ SOS AUTO-სთან ერთად თქვენ და თქვენი მანქანა საიმედო
          ხელში იქნებით!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          გამჭვირვალე ფასი და გადახდა
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაცია ფასს დაგითვლით კილომეტრაჟის მიხედვით, გადახდა კი
          შესაძლებელი იქნება როგორც გადარიცხვით, ასევე ნაღდი ანგარიშსწორებით.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          ხელმისაწვდომობა 24/7-ზე
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენი სერვისები ხელმისაწვდომია საქართველოს მასშტაბით, დღე-ღამის ნებისმიერ დროს, 24/7-ზე.
        </p>
        <p className="font-weight-bold text-light">
          ჩვენ აქ ვართ, რათა დაგეხმაროთ მაშინ, როცა ეს ყველაზე მეტად გჭირდებათ!
          <br />
          თქვენი კმაყოფილება ჩვენთვის პრიორიტეტულია!
        </p>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, რა იცით რა ხდება გზაზე!
        </p>
      </div>
    ),
  },
  tow: {
    title: 'ევაკუატორის მომსახურება',
    color: '#fff',
    img: 'img/sos/tow.webp',
    text: () => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#fec50c', margin: '30px' }}>SOS AUTO-ს აპლიკაციით გამოიძახეთ თქვენთან ყველაზე ახლოს მდებარე ავტოდახმარების სერვისები. </h4>
        </div>
        <p className="font-weight-bold text-light">
          პირველად საქართველოში SOS AUTO-ს აპლიკაცია - ,,სასწრაფო დახმარება თქვენი
          ავტომობილისთვის“. ჩვენ შევქმენით ვებ და მობილური აპლიკაცია სერვისის
          შემსრულებლებისა და მომხმარებლების დასაკავშირებლად.
        </p>
        <p className="font-weight-bold text-light">
          დაივიწყეთ ავტოდახმარების ძიებისა და ლოდინის სირთულე, გადმოწერეთ აპლიკაცია
          და მიიღეთ სასურველი დახმარება.
        </p>
        <p className="font-weight-bold text-light">
          მოითხოვეთ ევაკუატორის მომსახურება მარტივად, სწრაფად და ხარისხიანად!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          როგორ მუშაობს?
        </h5>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, გახსენით SOS AUTO-ს აპი თქვენს სმარტფონში,
          შეიყვანეთ თქვენი მდებარეობა და დანიშნულება, თქვენი მანქანის მოდელისა და
          დაზიანების მიხედვით აირჩიეთ სასურველი ევაკუატორი, აპლიკაცია კი მოგიძებნით
          ყველაზე ახლოს მდებარე დახმარებას და მოახდენს სწრაფ და ეფექტურ რეაგირებას.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          რეალურ დროში თვალყურის დევნება და განახლებები
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაციით თქვენ შეგიძლიათ რეალურ დროში თვალყური ადევნოთ
          მძღოლის გადაადგილებას და ზუსტად გეცოდინებათ, თუ რა დრო დასჭირდება
          დახმარებას თქვენამდე მოსასვლელად.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          პროფესიონალი და სანდო ავტოასისტენტები
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენ ვთანამშრომლობთ პროფესიონალ და სანდო ავტოასისტენტებთან.
          <br />
          დარწმუნებული იყავით, რომ SOS AUTO-სთან ერთად თქვენ და თქვენი მანქანა საიმედო
          ხელში იქნებით!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          გამჭვირვალე ფასი და გადახდა
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაცია ფასს დაგითვლით კილომეტრაჟის მიხედვით, გადახდა კი
          შესაძლებელი იქნება როგორც გადარიცხვით, ასევე ნაღდი ანგარიშსწორებით.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          ხელმისაწვდომობა 24/7-ზე
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენი სერვისები ხელმისაწვდომია საქართველოს მასშტაბით, დღე-ღამის ნებისმიერ დროს, 24/7-ზე.
        </p>
        <p className="font-weight-bold text-light">
          ჩვენ აქ ვართ, რათა დაგეხმაროთ მაშინ, როცა ეს ყველაზე მეტად გჭირდებათ!
          <br />
          თქვენი კმაყოფილება ჩვენთვის პრიორიტეტულია!
        </p>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, რა იცით რა ხდება გზაზე!
        </p>
      </div>
    ),
  },
  fuel: {
    title: 'საწვავის მიწოდება',
    color: '#fff',
    img: 'img/sos/fuel.jpg',
    text: () => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#fec50c', margin: '30px' }}>SOS AUTO-ს აპლიკაციით გამოიძახეთ თქვენთან ყველაზე ახლოს მდებარე ავტოდახმარების სერვისები. </h4>
        </div>
        <p className="font-weight-bold text-light">
          პირველად საქართველოში SOS AUTO-ს აპლიკაცია - ,,სასწრაფო დახმარება თქვენი
          ავტომობილისთვის“. ჩვენ შევქმენით ვებ და მობილური აპლიკაცია სერვისის
          შემსრულებლებისა და მომხმარებლების დასაკავშირებლად.
        </p>
        <p className="font-weight-bold text-light">
          დაივიწყეთ ავტოდახმარების ძიებისა და ლოდინის სირთულე, გადმოწერეთ აპლიკაცია
          და მიიღეთ სასურველი დახმარება.
        </p>
        <p className="font-weight-bold text-light">
          მოითხოვეთ საწვავის მიწოდების სერვისი მარტივად, სწრაფად და ხარისხიანად!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          როგორ მუშაობს?
        </h5>
        <p className="font-weight-bold text-light">
          შემთხვევით, თუ გზაზე საწვავი გაგითავდათ, SOS AUTO-ს აპლიკაციიდან უმარტივესად
          შეგიძლიათ გამოიძახოთ საწვავის მიწოდების სერვისი.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          რეალურ დროში თვალყურის დევნება და განახლებები
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაციით თქვენ შეგიძლიათ რეალურ დროში თვალყური ადევნოთ
          მძღოლის გადაადგილებას და ზუსტად გეცოდინებათ, თუ რა დრო დასჭირდება
          დახმარებას თქვენამდე მოსასვლელად.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          პროფესიონალი და სანდო ავტოასისტენტები
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენ ვთანამშრომლობთ პროფესიონალ და სანდო ავტოასისტენტებთან.
          <br />
          დარწმუნებული იყავით, რომ SOS AUTO-სთან ერთად თქვენ და თქვენი მანქანა საიმედო
          ხელში იქნებით!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          გამჭვირვალე ფასი და გადახდა
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაცია ფასს დაგითვლით კილომეტრაჟის მიხედვით, გადახდა კი
          შესაძლებელი იქნება როგორც გადარიცხვით, ასევე ნაღდი ანგარიშსწორებით.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          ხელმისაწვდომობა 24/7-ზე
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენი სერვისები ხელმისაწვდომია საქართველოს მასშტაბით, დღე-ღამის ნებისმიერ დროს, 24/7-ზე.
        </p>
        <p className="font-weight-bold text-light">
          ჩვენ აქ ვართ, რათა დაგეხმაროთ მაშინ, როცა ეს ყველაზე მეტად გჭირდებათ!
          <br />
          თქვენი კმაყოფილება ჩვენთვის პრიორიტეტულია!
        </p>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, რა იცით რა ხდება გზაზე!
        </p>
      </div>
    ),
  },
  sober: {
    title: 'მძღოლის მომსახურება',
    color: '#fff',
    img: 'img/sos/driver.jpg',
    text: () => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h4 style={{ color: '#fec50c', margin: '30px' }}>SOS AUTO-ს აპლიკაციით გამოიძახეთ თქვენთან ყველაზე ახლოს მდებარე ავტოდახმარების სერვისები. </h4>
        </div>
        <p className="font-weight-bold text-light">
          პირველად საქართველოში SOS AUTO-ს აპლიკაცია - ,,სასწრაფო დახმარება თქვენი
          ავტომობილისთვის“. ჩვენ შევქმენით ვებ და მობილური აპლიკაცია სერვისის
          შემსრულებლებისა და მომხმარებლების დასაკავშირებლად.
        </p>
        <p className="font-weight-bold text-light">
          დაივიწყეთ ავტოდახმარების ძიებისა და ლოდინის სირთულე, გადმოწერეთ აპლიკაცია
          და მიიღეთ სასურველი დახმარება.
        </p>
        <p className="font-weight-bold text-light">
          ნასვამ მდგომარეობაში, რა თქმა უნდა, რულთან არ დაჯდებით, SOS AUTO-ს
          საშუალებით მოითხოვეთ მძღოლის მომსახურება მარტივად, სწრაფად და ხარისხიანად!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          როგორ მუშაობს?
        </h5>
        <p className="font-weight-bold text-light">
          ნებისმიერ სიტუაციაში შეგიძლიათ მშვიდად გაატაროთ დრო მეგობრებთან ერთად,
          შემდეგ კი SOS AUTO-ს აპლიკაციიდან გამოიძახოთ მძღოლი, რომელიც თქვენივე
          მანქანით მიგიყვანთ სახლში.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          რეალურ დროში თვალყურის დევნება და განახლებები
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაციით თქვენ შეგიძლიათ რეალურ დროში თვალყური ადევნოთ
          მძღოლის გადაადგილებას და ზუსტად გეცოდინებათ, თუ რა დრო დასჭირდება
          დახმარებას თქვენამდე მოსასვლელად.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          პროფესიონალი და სანდო ავტოასისტენტები
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენ ვთანამშრომლობთ პროფესიონალ და სანდო ავტოასისტენტებთან.
          <br />
          დარწმუნებული იყავით, რომ SOS AUTO-სთან ერთად თქვენ და თქვენი მანქანა საიმედო
          ხელში იქნებით!
        </p>
        <h5 style={{ color: '#fec50c' }}>
          გამჭვირვალე ფასი და გადახდა
        </h5>
        <p className="font-weight-bold text-light">
          SOS AUTO-ს აპლიკაცია ფასს დაგითვლით კილომეტრაჟის მიხედვით, გადახდა კი
          შესაძლებელი იქნება როგორც გადარიცხვით, ასევე ნაღდი ანგარიშსწორებით.
        </p>
        <h5 style={{ color: '#fec50c' }}>
          ხელმისაწვდომობა 24/7-ზე
        </h5>
        <p className="font-weight-bold text-light">
          ჩვენი სერვისები ხელმისაწვდომია საქართველოს მასშტაბით, დღე-ღამის ნებისმიერ დროს, 24/7-ზე.
        </p>
        <p className="font-weight-bold text-light">
          ჩვენ აქ ვართ, რათა დაგეხმაროთ მაშინ, როცა ეს ყველაზე მეტად გჭირდებათ!
          <br />
          თქვენი კმაყოფილება ჩვენთვის პრიორიტეტულია!
        </p>
        <p className="font-weight-bold text-light">
          გადმოწერეთ SOS AUTO-ს აპლიკაცია, რა იცით რა ხდება გზაზე!
        </p>
      </div>
    ),
  },
};
