/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';

import './ProfilePage.css';
import './map.css';
import Modal from 'react-bootstrap/Modal';
import apiCall from '../../api';
import { useUser } from '../../lib/atoms';
import maps from '../../lib/maps';
import consts from '../../lib/consts';
import classNames from '../../lib/classNames';
import translations from '../../lib/translations';
import texts from '../../lib/texts';

function ServicesMap() {
  const lang = 'ka';
  const { user, userType } = useUser();
  const [serviceList, setServiceList] = useState(null);
  const [gMap, setGMap] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (gMap && serviceList) {
      const bounds = new window.google.maps.LatLngBounds();
      serviceList.forEach(({ loc }) => bounds.extend(loc));
      gMap.fitBounds(bounds);
    }
  }, [gMap, serviceList]);

  const getServiceInfoRequest = async () => {
    try {
      let services = [];
      const resp = await apiCall(
        '/get-services-info',
        {
          uid: user.uid,
          idToken: user.accessToken,
        },
      );
      console.log(resp);
      services = resp.services.filter((s) => s.data.loc).map((s) => ({ ...s.data, id: s.id }));

      console.log({ services });
      setServiceList(services);
    } catch (error) {
      console.error('Error getting service!', error);
    }
  };

  useEffect(() => {
    getServiceInfoRequest();
  }, [user, userType]);

  const containerStyle = {
    width: '100%',
    height: '60vh',
  };

  const carTypeToIco = {
    sedan: '/img/car-c.png',
    tow: '/img/tow-truck-c.png',
    tow_spider: '/img/tow-truck-sp-c.png',
    tow_jumbo: '/img/tow-truck-jumbo-c.png',
  };

  const getCarIcon = (carType) => ({
    url: carTypeToIco[carType] || '/img/sos/car.png',
    scaledSize: { width: 40, height: 40 },
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: consts.MAPS_API,
  });

  return (
    <div>
      {isLoaded
        && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={17}
          options={{ styles: maps.streetStyle }}
          onLoad={setGMap}
        >
          {serviceList && serviceList.map((s) => {
            console.log(getCarIcon(s.carType));
            return (
              <MarkerF
                key={s.id}
                position={s.loc}
                icon={getCarIcon(s.carType)}
                onClick={() => {
                  setOpenModal(true);
                  setService(s);
                }}
              />
            );
          })}
        </GoogleMap>
        )}

      <Modal className="text-center text-light" show={openModal} onHide={() => setOpenModal(false)} centered>
        <Modal.Header className="text-light" closeButton>
          <Modal.Title>{service?.carNumber || ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {service
            && (
            <>

              <div className={classNames.FROM_GROUP_ROW}>
                <span>
                  Type:
                  {' '}
                  {texts.carType[service.carType][lang]}
                </span>
              </div>

              <div className={classNames.FROM_GROUP_ROW}>
                {service.verified && (
                <span>
                  Verified
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-check" />
                  </a>
                </span>
                )}
                {!service.verified && (
                <span>
                  Not Verified
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-times" />
                  </a>
                  {' '}
                </span>
                )}
              </div>

              <div className={classNames.FROM_GROUP_ROW}>
                {service.status && (
                <span>
                  Enabled
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-check" />
                  </a>
                </span>
                )}
                {!service.verified && (
                <span>
                  Not Enabled
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-times" />
                  </a>
                  {' '}
                </span>
                )}
              </div>

              <div className={classNames.FROM_GROUP_ROW}>
                <span>
                  Phone:
                  {' '}
                  {service.phone}
                </span>
              </div>

              <div className={classNames.FROM_GROUP_ROW}>
                <span>
                  {translations.profile.services.REVIEWS[lang]}
                  :
                  {' '}
                  {service.reviewsAvarage}
                  {' '}
                  (
                  {service.reviews}
                  )
                </span>
              </div>
            </>
            )}

        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ServicesMap;
